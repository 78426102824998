<template>
  <div
    v-if="!subscriptionIsFreeTrial && $store.state.idea.storyMode.common.completedSteps.marketPotentialCompleted && isMarketPotentialVisible"
    class="journal-content dynamic-height"
  >
    <div class="content-menus">
      <div v-if="moreVisible === true" class="view-type">
        <a
          v-if="totalRevenue"
          class="orange"
          :class="{'orange-off': !isOption(marketPotentialData, 'marketPotentialTable')}"
          @click="enableMarketPotential"
        >
          {{ $t('pages.businessPlan.sections.marketPotential.marketPotentialTable') }}
        </a>
        <p v-if="!totalRevenue">
          {{ $t('pages.businessPlan.sections.youDontHaveAnyDataOnThisPage') }}
        </p>
      </div>
      <div v-if="canEdit" class="mini-actions">
        <toggle-page-items-button
          :visible="moreVisible"
          @click="toggleMore"
        />
        <toggle-view-button
          :disabled="!cardExist"
          :horizontal="!isOption(marketPotentialData, 'gridListView')"
          @click="toggleRotate"
        />
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(marketPotentialData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-research-market-potential" />
      </div>
    </div>

    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="marketPotentialData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="marketPotentialData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Description section -->
      </header>

      <template v-if="totalRevenue">
        <div v-if="isOption(marketPotentialData, 'marketPotentialTable')" class="market-potential-wrapper">
          <div class="market-potential-inner market-potential-inner__left">
            <div
              v-if="totalRevenue"
              class="potential-card"
            >
              <p class="title">
                {{ $t('pages.businessGuide.marketPotential.steps.totalAddressableMarket.title') }}
              </p>
              <p class="number-holder">
                {{ currencySymbol }}{{ getFormattedAmount(totalRevenue) }}
              </p>
            </div>

            <div
              v-if="availableTotalRevenue"
              class="potential-card"
            >
              <p class="title">
                {{ $t('pages.businessGuide.marketPotential.steps.serviceableAvailableMarket.title') }}
              </p>
              <p class="number-holder">
                {{ currencySymbol }}{{ getFormattedAmount(availableTotalRevenue) }}
              </p>
            </div>

            <div
              v-if="shareTotalRevenue"
              class="potential-card"
            >
              <p class="title">
                {{ $t('pages.businessGuide.marketPotential.steps.shareOfMarket.title') }}
              </p>
              <p class="number-holder">
                {{ currencySymbol }}{{ getFormattedAmount(shareTotalRevenue) }}
              </p>
            </div>
          </div>
          <div class="market-potential-inner">
            <div class="market-display" :class="{'filled': totalRevenue}">
              <div class="market-heading">
                <h3 v-if="totalRevenue">
                  {{ $t('pages.businessPlan.sections.marketPotential.illustrationTitle') }}
                </h3>
              </div>

              <template v-if="totalRevenue">
                <div class="market-char_wrapper">
                  <div class="market-char market-char_total">
                    <ib-tooltip popper-class="market-tooltip" :open-delay="0">
                      <div slot="content">
                        <span>TAM</span>
                        <br>
                        <p>{{ currencySymbol }}{{ getFormattedAmount(totalRevenue) }}</p>
                      </div>
                      <svg viewBox="0 0 80 80">
                        <circle class="circle" cx="40" cy="40" r="40" />
                      </svg>
                    </ib-tooltip>
                  </div>
                  <div v-if="availableTotalRevenue" class="market-char market-char_available">
                    <ib-tooltip popper-class="market-tooltip" :open-delay="0">
                      <div slot="content">
                        <span>SAM</span>
                        <br>
                        <p>{{ currencySymbol }}{{ getFormattedAmount(availableTotalRevenue) }}</p>
                      </div>
                      <svg viewBox="0 0 80 80">
                        <circle class="circle" cx="40" cy="40" r="40" />
                      </svg>
                    </ib-tooltip>
                  </div>
                  <div v-if="shareTotalRevenue" class="market-char market-char_share">
                    <ib-tooltip popper-class="market-tooltip" :open-delay="0">
                      <div slot="content">
                        <span>SOM</span>
                        <br>
                        <p>{{ currencySymbol }}{{ getFormattedAmount(shareTotalRevenue) }}</p>
                      </div>
                      <svg viewBox="0 0 80 80">
                        <circle class="circle" cx="40" cy="40" r="40" />
                      </svg>
                    </ib-tooltip>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>

      <!-- More Details section -->
      <template v-if="moreDetails && isOption(marketPotentialData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="marketPotentialData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- More Details section -->
    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import TogglePageItemsButton from '@/views/Home/StoryMode/Components/BusinessPlan/TogglePageItemsButton'
import ToggleViewButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleViewButton'
import currencies from '@/mixins/currencies'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'MarketPotential',

  components: {
    EditSectionLink,
    EditableDescription,
    EditableTitle,
    ToggleNotesButton,
    TogglePageItemsButton,
    ToggleViewButton
  },

  mixins: [
    journalMethods,
    currencies,
    MixinIdeaRoles,
    MixinUserSubscription
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardExist: false,
      moreVisible: false,
      marketPotentialData: {},
      marketPotential: [],
      totalRevenue: null,
      availableTotalRevenue: null,
      shareTotalRevenue: null,
      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.marketPotentialMoreDetails
    }
  },

  computed: {
    isMarketPotentialVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'MarketPotential')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    isTitleEnabled () {
      return this.marketPotentialData.title
    },

    isMoreDetailsTitleEnabled () {
      return this.marketPotentialData.moreDetailsTitle
    }
  },

  watch: {
    allPagesTextData () {
      this.marketPotentialData = Object.assign({}, this.allPagesTextData)
    }
  },

  mounted () {
    this.marketPotentialData = Object.assign({}, this.allPagesTextData)
    this.getMarketPotential()
  },

  methods: {
    getMarketPotential () {
      if (this.$store.state.idea.storyMode.research.marketPotential) {
        this.marketPotential = this.$store.state.idea.storyMode.research.marketPotential
        this.calculateMarketPotential()

        return
      }

      if (this.$store.getters['user/isLoggedIn']) {
        this.$http.get('story-mode/research/market-potential', { params: { ideaId: this.$store.state.idea.id } })
          .then(response => {
            this.marketPotential = response.data.payload.marketPotential
            this.$store.commit('idea/setMarketPotential', response.data.payload.marketPotential)
            this.calculateMarketPotential()
          })
      }
    },

    calculateMarketPotential () {
      if (!this.marketPotential.total_population && !this.marketPotential.potential_customers && !this.marketPotential.annual_spending) {
        this.totalRevenue = null
      } else {
        this.totalRevenue = this.marketPotential.total_population * (this.marketPotential.potential_customers / 100) * this.marketPotential.annual_spending
      }

      if (!this.marketPotential.target_customers) {
        this.availableTotalRevenue = null
      } else {
        this.availableTotalRevenue = this.totalRevenue * (this.marketPotential.target_customers / 100)
      }

      if (!this.marketPotential.market_share) {
        this.shareTotalRevenue = null
      } else {
        this.shareTotalRevenue = this.availableTotalRevenue * (this.marketPotential.market_share / 100)
      }

      this.loading = false
    },

    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.marketPotentialData, 'gridListView')
        this.marketPotentialData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.marketPotentialData, 'moreDetailsDisplay')
        this.marketPotentialData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMarketPotential () {
      this.toggleOption(this.marketPotentialData, 'marketPotentialTable')
      this.marketPotentialData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    },

    onSave () {
      this.$emit('save', this.marketPotentialData)
    }
  }
}
</script>
