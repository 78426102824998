<template>
  <div
    v-if="$store.state.idea.storyMode.common.completedSteps.distributionCompleted === true && isDistributionVisible"
    class="journal-content dynamic-height"
    :class="{'dynamic-height': distributions.length > 10 }"
  >
    <div class="content-menus">
      <div v-if="moreVisible === true" class="view-type">
        <a
          v-if="distributions.length !== 0"
          class="orange"
          :class="{'orange-off': !isOption(distributionData, 'distributionItemsDisplay')}"
          @click="enableDistribution"
        >
          {{ $t('pages.businessPlan.sections.distribution.distributionItems') }}
        </a>
        <p v-if="distributions.length === 0">
          {{ $t('pages.businessPlan.sections.youDontHaveAnyDataOnThisPage') }}
        </p>
      </div>
      <div v-if="canEdit" class="mini-actions">
        <toggle-page-items-button
          :visible="moreVisible"
          @click="toggleMore"
        />
        <toggle-view-button
          :disabled="!cardExist"
          :horizontal="!isOption(distributionData, 'gridListView')"
          @click="toggleRotate"
        />
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(distributionData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-concept-distribution" />
      </div>
    </div>
    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="distributionData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="distributionData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Description section -->
      </header>

      <template v-if="distributions.length !== 0">
        <div
          v-if="isOption(distributionData, 'distributionItemsDisplay')"
          class="table-wrapper"
          :class="{'list-view': !isOption(distributionData, 'gridListView')}"
        >

          <div
            v-for="promoItem in distributions"
            :key="promoItem.id"
            class="journal-card distribution-card"
          >
            <div
              class="card-top" :class="[{'default': !promoItem.image}, promoItem.type]"
              :style="cardImageBySize(promoItem.image, 'medium')"
            />
            <div class="card-bottom">
              <h3>{{ promoItem.name }}</h3>
              <div class="dashed-divider" />
              <p class="pre-formatted desc">
                {{ promoItem.description }}
              </p>
            </div>
          </div>
        </div>
      </template>

      <!-- More Details section -->
      <template v-if="moreDetails && isOption(distributionData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="distributionData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- More Details section -->
    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import TogglePageItemsButton from '@/views/Home/StoryMode/Components/BusinessPlan/TogglePageItemsButton'
import ToggleViewButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleViewButton'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'Distribution',

  components: {
    EditSectionLink,
    EditableDescription,
    EditableTitle,
    ToggleNotesButton,
    TogglePageItemsButton,
    ToggleViewButton
  },

  mixins: [
    journalMethods,
    MixinIdeaRoles
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardExist: true,
      distributionData: {},
      moreVisible: false,
      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.distributionMoreDetails,
      distributions: this.$store.state.idea.storyMode.concept.distribution.distributions
    }
  },

  computed: {
    isDistributionVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'Distribution')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    isTitleEnabled () {
      return this.distributionData.title
    },

    isMoreDetailsTitleEnabled () {
      return this.distributionData.moreDetailsTitle
    }
  },

  watch: {
    allPagesTextData () {
      this.distributionData = Object.assign({}, this.allPagesTextData)
    }
  },

  mounted () {
    this.distributionData = Object.assign({}, this.allPagesTextData)
  },

  methods: {
    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.distributionData, 'gridListView')
        this.distributionData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.distributionData, 'moreDetailsDisplay')
        this.distributionData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    onSave () {
      this.$emit('save', this.distributionData)
    },

    enableDistribution () {
      this.toggleOption(this.distributionData, 'distributionItemsDisplay')
      this.distributionData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    }
  }
}
</script>
