<template>
  <div
    v-if="isVisible"
    class="journal-content dynamic-height"
  >
    <div class="content-menus">
      <div v-if="canEdit" class="mini-actions p-2">
        <ib-tooltip popper-class="plan-popper">
          <!-- Tooltip Content -->
          <div
            slot="content"
            class="tooltip-content"
          >
            <p>{{ $t('pages.businessPlan.deletePage') }}</p>
          </div>
          <!-- /Tooltip Content -->

          <ib-tooltip-confirm
            placement="bottom"
            :text="$t('pages.businessPlan.areYouSureWantToDeleteThisPage')"
            @confirm="onDelete"
          >
            <div class="trash-icon-wrapper">
              <i class="ib-icon-trash h5 cursor-pointer" />
            </div>
          </ib-tooltip-confirm>
        </ib-tooltip>
      </div>
    </div>

    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="customData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- /Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="customData.subTitle"
          :disabled="!canEdit || shareMode"
          :limit-characters="5000"
          :empty-text="$t('addMoreDetails.addNoteText')"
          @save="onSave"
        />
        <!-- /Description section -->
      </header>
    </div>
  </div>
</template>

<script>
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import IbTooltipConfirm from '@/components/_v2/IbTooltipConfirm'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { mapGetters } from 'vuex'

export default {
  name: 'Custom',

  components: {
    EditableDescription,
    EditableTitle,
    IbTooltipConfirm
  },

  mixins: [
    MixinIdeaRoles
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      customData: {}
    }
  },

  computed: {
    ...mapGetters('idea', [
      'getBusinessPlanSections'
    ]),

    isVisible () {
      const index = this.getBusinessPlanSections.indexOf(
        this.getBusinessPlanSections.find(journalElement => journalElement.id === this.customData.id)
      )
      if (index === -1) return false

      return !this.getBusinessPlanSections[index].options.includes('switch')
    }
  },

  watch: {
    allPagesTextData () {
      this.customData = Object.assign({}, this.allPagesTextData)
    }
  },

  mounted () {
    this.customData = Object.assign({}, this.allPagesTextData)
  },

  methods: {
    onSave () {
      this.$emit('save', this.customData)
    },

    onDelete () {
      this.$emit('delete', this.allPagesTextData.id)
    }
  }
}
</script>

<style scoped lang="scss">
.trash-icon-wrapper {
  transition: all .25s;
  opacity: 0.6;
  color: $color-white;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }

  i {
    font-size: 20px;
  }
}
</style>
