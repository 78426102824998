<template>
  <div
    v-if="!subscriptionIsFreeTrial && $store.state.idea.storyMode.common.completedSteps.ownershipCompleted === true && isOwnershipVisible"
    class="journal-content dynamic-height"
  >
    <div class="content-menus">
      <div v-if="moreVisible" class="view-type">
        <a
          class="orange"
          :class="{'orange-off': !isOption(ownershipData, 'ownershipStructure')}"
          @click="enableOwnership('ownershipStructure')"
        >
          {{ $t('pages.businessGuide.ownership.structure') }}
        </a>
        <a
          class="orange"
          :class="{'orange-off': !isOption(ownershipData, 'ownershipOwners')}"
          @click="enableOwnership('ownershipOwners')"
        >
          {{ $t('pages.businessGuide.ownership.owners') }}
        </a>
      </div>
      <div v-if="canEdit" class="mini-actions">
        <toggle-page-items-button
          :visible="moreVisible"
          @click="toggleMore"
        />
        <toggle-view-button
          :disabled="!cardExist"
          :horizontal="!isOption(ownershipData, 'gridListView')"
          @click="toggleRotate"
        />
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(ownershipData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-set-up-ownership" />
      </div>
    </div>

    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="ownershipData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- /Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="ownershipData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- /Description section -->
      </header>

      <!-- Content -->
      <div class="ownership-wrapper">
        <div
          v-if="ownershipType && isOption(ownershipData, 'ownershipStructure')"
          class="structure"
        >
          <h4 class="mt-4 mb-0">
            {{ $t('pages.businessGuide.ownership.businessStructure') }}
          </h4>
          <p class="h4 mb-5 font-outfit-semi-bold">
            {{ $t(ownershipType) }}
          </p>
        </div>

        <template v-if="ownership.owners.length > 0">
          <div
            v-if="isOption(ownershipData, 'ownershipOwners')"
            class="owners"
          >
            <el-row class="d-flex">
              <el-col :span="12" class="owners-list">
                <h4 class="owners-list-title">
                  {{ $t('pages.businessGuide.ownership.owners') }}
                </h4>
                <owners-list-table
                  :owners="ownership.owners"
                  :header="false"
                  class="p-0"
                />
              </el-col>
              <el-col :span="12" class="owners-chart">
                <h4 class="chart-title text-center">
                  {{ $t('pages.businessGuide.ownership.share') }}
                </h4>
                <div>
                  <owners-chart :height="300" />
                </div>
              </el-col>
            </el-row>
          </div>
        </template>
      </div>

      <!-- More Details section -->
      <template v-if="moreDetails && isOption(ownershipData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="ownershipData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- /More Details section -->

    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import OwnersChart from '@/views/Home/StoryMode/SetUp/Ownership/Components/Charts/OwnersChart/OwnersChart'
import OwnersListTable from '@/views/Home/StoryMode/SetUp/Ownership/Components/OwnersList/OwnersListTable'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import TogglePageItemsButton from '@/views/Home/StoryMode/Components/BusinessPlan/TogglePageItemsButton'
import ToggleViewButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleViewButton'
import journalMethods from '@/mixins/journalMethods'

import OwnershipTypesEnum, { OwnershipTypes } from '@/constants/OwnershipTypesEnum'

export default {
  name: 'Ownership',

  components: {
    EditSectionLink,
    EditableDescription,
    EditableTitle,
    OwnersChart,
    OwnersListTable,
    ToggleNotesButton,
    TogglePageItemsButton,
    ToggleViewButton
  },

  mixins: [
    journalMethods,
    MixinIdeaRoles,
    MixinUserSubscription
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardExist: false,
      moreVisible: false,
      ownershipData: {},
      types: OwnershipTypesEnum,
      ownershipTypes: OwnershipTypes,
      ownership: this.$store.state.idea.ownership.ownership,
      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.ownershipMoreDetails
    }
  },

  computed: {
    isOwnershipVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'Ownership')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    ownershipType () {
      const foundType = this.ownershipTypes.find(x => x.id === this.ownership.type)

      if (foundType) {
        return foundType.id === this.types.OTHER ? this.ownership.otherType : foundType.name
      }

      return null
    }
  },

  watch: {
    allPagesTextData () {
      this.ownershipData = Object.assign({}, this.allPagesTextData)
    }
  },

  mounted () {
    this.ownershipData = Object.assign({}, this.allPagesTextData)
  },

  methods: {
    onSave () {
      this.$emit('save', this.ownershipData)
    },

    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.ownershipData, 'gridListView')
        this.ownershipData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.ownershipData, 'moreDetailsDisplay')
        this.ownershipData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableOwnership (option) {
      this.toggleOption(this.ownershipData, option)
      this.ownershipData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    }
  }
}
</script>

<style scoped lang="scss">
.ownership-wrapper {
  .owners {
    border: 2px solid rgba(84, 89, 113, 0.25);

    .owners-list {
      padding: 16px 24px;

      .owners-list-title {
        margin: 0 0 10px 0;
      }
    }

    .owners-chart {
      padding: 16px 24px;
      border-left: 2px solid rgba(84, 89, 113, 0.25);

      .chart-title {
        margin: 0 0 10px 0;
      }
    }

    ::v-deep {
      .owners-list-table {
        max-height: unset;
        padding-left: 0 !important;

        & tr td {
          &.percentage {
            font-size: 18px;
          }

          &.color-wrapper {
            width: 35px;
          }
        }
      }
    }
  }
}
</style>
