<template>
  <div
    v-if="!subscriptionIsFreeTrial && $store.state.idea.storyMode.common.completedSteps.managementTeamCompleted === true && isManagementTeamVisible === true"
    class="journal-content dynamic-height"
    :class="{'dynamic-height': managementTeam.length > 8 }"
  >
    <div class="content-menus">
      <div v-if="moreVisible === true" class="view-type">
        <a
          v-if="managementTeam.length !== 0"
          class="orange"
          :class="{'orange-off': !isOption(managementTeamData, 'managersTable')}"
          @click="enableManagers"
        >
          {{ $t('pages.businessPlan.sections.managementTeam.managersTable') }}
        </a>
        <p v-if="managementTeam.length === 0">
          {{ $t('pages.businessPlan.sections.youDontHaveAnyDataOnThisPage') }}
        </p>
      </div>
      <div v-if="canEdit" class="mini-actions">
        <toggle-page-items-button
          :visible="moreVisible"
          @click="toggleMore"
        />
        <toggle-view-button
          :disabled="!cardExist"
          :horizontal="!isOption(managementTeamData, 'gridListView')"
          @click="toggleRotate"
        />
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(managementTeamData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-set-up-management-team" />
      </div>
    </div>

    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="managementTeamData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="managementTeamData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Description section -->
      </header>

      <template v-if="managementTeam.length !== 0">
        <div
          v-if="isOption(managementTeamData, 'managersTable')"
          class="table-wrapper"
          :class="{'list-view': !isOption(managementTeamData, 'gridListView')}"
        >
          <div
            v-for="manager in managementTeam"
            :key="manager.id"
            class="journal-card member-card"
          >
            <div class="card-top">
              <div
                class="avatar-holder"
                :class="{'default': !manager.image }"
                :style="cardImageBySize(manager.image, 'medium')"
              />
            </div>
            <div class="card-bottom">
              <h3>{{ manager.name }}</h3>
              <div class="dashed-divider" />
              <p class="position">
                {{ manager.position }}
              </p>
              <div class="dashed-divider" />
              <p class="pre-formatted">
                {{ manager.description }}
              </p>
            </div>
          </div>
        </div>
      </template>

      <!-- More Details section -->
      <template v-if="moreDetails && isOption(managementTeamData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="managementTeamData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- More Details section -->
    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import TogglePageItemsButton from '@/views/Home/StoryMode/Components/BusinessPlan/TogglePageItemsButton'
import ToggleViewButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleViewButton'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'ManagementTeam',

  components: {
    EditSectionLink,
    EditableDescription,
    EditableTitle,
    ToggleNotesButton,
    TogglePageItemsButton,
    ToggleViewButton
  },

  mixins: [
    journalMethods,
    MixinIdeaRoles,
    MixinUserSubscription
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardExist: true,
      managementTeamData: {},
      moreVisible: false,
      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.managementTeamMoreDetails,
      managementTeam: this.$store.state.idea.storyMode.setUp.managementTeam.members
    }
  },

  computed: {
    isManagementTeamVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'ManagementTeam')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    isTitleEnabled () {
      return this.managementTeamData.title
    },

    isMoreDetailsTitleEnabled () {
      return this.managementTeamData.moreDetailsTitle
    }
  },

  watch: {
    allPagesTextData () {
      this.managementTeamData = Object.assign({}, this.allPagesTextData)
    }
  },

  mounted () {
    this.managementTeamData = Object.assign({}, this.allPagesTextData)
  },

  methods: {
    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.managementTeamData, 'gridListView')
        this.managementTeamData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.managementTeamData, 'moreDetailsDisplay')
        this.managementTeamData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    onSave () {
      this.$emit('save', this.managementTeamData)
    },

    enableManagers () {
      this.toggleOption(this.managementTeamData, 'managersTable')
      this.managementTeamData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    }
  }
}
</script>
