<template>
  <div
    v-if="$store.state.idea.storyMode.common.completedSteps.partnerCompleted === true && isPartnerVisible"
    class="journal-content dynamic-height"
    :class="{'dynamic-height': partners.length > 10 }"
  >
    <div class="content-menus">
      <div v-if="moreVisible === true" class="view-type">
        <a
          v-if="partners.length !== 0"
          class="orange"
          :class="{'orange-off': !isOption(partnerData, 'partnerItemsDisplay')}"
          @click="enablePartner"
        >
          {{ $t('pages.businessPlan.sections.partners.partnerItems') }}
        </a>
        <p v-if="partners.length === 0">
          {{ $t('pages.businessPlan.sections.youDontHaveAnyDataOnThisPage') }}
        </p>
      </div>
      <div v-if="canEdit" class="mini-actions">
        <toggle-page-items-button
          :visible="moreVisible"
          @click="toggleMore"
        />
        <toggle-view-button
          :disabled="!cardExist"
          :horizontal="!isOption(partnerData, 'gridListView')"
          @click="toggleRotate"
        />
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(partnerData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-concept-partners" />
      </div>
    </div>
    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="partnerData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="partnerData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Description section -->
      </header>

      <template v-if="partners.length !== 0">
        <div
          v-if="isOption(partnerData, 'partnerItemsDisplay')"
          class="table-wrapper"
          :class="{'list-view': !isOption(partnerData, 'gridListView')}"
        >

          <div
            v-for="promoItem in partners"
            :key="promoItem.id"
            class="journal-card partner-card"
          >
            <div
              class="card-top"
              :class="[{'default': !promoItem.image}, promoItem.type]"
              :style="cardImageBySize(promoItem.image, 'medium')"
            />
            <div class="card-bottom">
              <h3>{{ promoItem.name }}</h3>
              <div class="dashed-divider" />
              <p class="pre-formatted desc">
                {{ promoItem.description }}
              </p>
            </div>
          </div>
        </div>
      </template>

      <!-- More Details section -->
      <template v-if="moreDetails && isOption(partnerData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="partnerData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- More Details section -->
    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import TogglePageItemsButton from '@/views/Home/StoryMode/Components/BusinessPlan/TogglePageItemsButton'
import ToggleViewButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleViewButton'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'Partner',

  components: {
    EditSectionLink,
    EditableDescription,
    EditableTitle,
    ToggleNotesButton,
    TogglePageItemsButton,
    ToggleViewButton
  },

  mixins: [
    journalMethods,
    MixinIdeaRoles
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardExist: true,
      partnerData: {},
      moreVisible: false,
      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.partnerMoreDetails,
      partners: this.$store.state.idea.storyMode.concept.partner.partners
    }
  },

  computed: {
    isPartnerVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'Partner')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    isTitleEnabled () {
      return this.partnerData.title
    },

    isMoreDetailsTitleEnabled () {
      return this.partnerData.moreDetailsTitle
    }
  },

  watch: {
    allPagesTextData () {
      this.partnerData = Object.assign({}, this.allPagesTextData)
    }
  },

  mounted () {
    this.partnerData = Object.assign({}, this.allPagesTextData)
  },

  methods: {
    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.partnerData, 'gridListView')
        this.partnerData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.partnerData, 'moreDetailsDisplay')
        this.partnerData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    onSave () {
      this.$emit('save', this.partnerData)
    },

    enablePartner () {
      this.toggleOption(this.partnerData, 'partnerItemsDisplay')
      this.partnerData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    }
  }
}
</script>
