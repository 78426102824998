<template>
  <div
    v-if="!subscriptionIsFreeTrial && $store.state.idea.storyMode.common.completedSteps.competitorsCompleted === true && isCompetitorsVisible"
    class="journal-content dynamic-height"
    :class="{'dynamic-height': competitors.length > 4 }"
  >
    <div class="content-menus">
      <div v-if="moreVisible === true" class="view-type">
        <a
          v-if="competitors.length !== 0"
          class="orange"
          :class="{'orange-off': !isOption(competitorData, 'competitorTable')}"
          @click="enableCompetitors"
        >
          {{ $t('pages.businessPlan.sections.competitors.competitorTable') }}
        </a>
        <p v-if="competitors.length === 0">
          {{ $t('pages.businessPlan.sections.youDontHaveAnyDataOnThisPage') }}
        </p>
      </div>
      <div v-if="canEdit" class="mini-actions">
        <toggle-page-items-button
          :visible="moreVisible"
          @click="toggleMore"
        />
        <toggle-view-button
          :disabled="!cardExist"
          :horizontal="!isOption(competitorData, 'gridListView')"
          @click="toggleRotate"
        />
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(competitorData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-research-competitors" />
      </div>
    </div>

    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="competitorData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="competitorData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Description section -->
      </header>

      <template v-if="competitors.length !== 0">
        <div
          v-if="isOption(competitorData, 'competitorTable')"
          class="table-wrapper"
          :class="{'list-view': !isOption(competitorData, 'gridListView')}"
        >
          <!--<div class="card-wrapper">-->
          <div
            v-for="competitor in competitors"
            :key="competitor.id"
            class="journal-card competitor-card"
          >
            <div
              class="card-top"
              :class="{'default': !competitor.image}"
              :style="cardImageBySize(competitor.image, 'medium')"
            >

              <div
                class="influence"
                :class="{ 'yellow': competitor.direct }"
              >

                <p>{{ competitor.direct ? $t('direct') : $t('indirect') }}</p>
              </div>
            </div>

            <div class="card-bottom">

              <div class="top-segment">
                <h3>{{ competitor.name }}</h3>

                <div class="dashed-divider" />
                <p class="pre-formatted desc">
                  {{ competitor.description }}
                </p>
                <div class="dashed-divider" />

                <ul>

                  <!-- Advantage 1 -->
                  <li>
                    <p>{{ competitor.advantageTitle1 }}</p>
                    <p class="bold">
                      {{ calculateAdvantageStrength(competitor.advantageValue1) }}
                    </p>
                  </li>
                  <!-- /Advantage 1 -->

                  <!-- Advantage 2 -->
                  <li>
                    <p>{{ competitor.advantageTitle2 }}</p>
                    <p class="bold">
                      {{ calculateAdvantageStrength(competitor.advantageValue2) }}
                    </p>
                  </li>
                  <!-- /Advantage 2 -->

                  <!-- Advantage 3 -->
                  <li>
                    <p>{{ competitor.advantageTitle3 }}</p>
                    <p class="bold">
                      {{ calculateAdvantageStrength(competitor.advantageValue3) }}
                    </p>
                  </li>
                  <!-- /Advantage 3 -->

                  <!-- Advantage 4 -->
                  <li>
                    <p>{{ competitor.advantageTitle4 }}</p>
                    <p class="bold">
                      {{ calculateAdvantageStrength(competitor.advantageValue4) }}
                    </p>
                  </li>
                  <!-- /Advantage 4 -->

                  <!-- Advantage 5 -->
                  <li>
                    <p>{{ competitor.advantageTitle5 }}</p>
                    <p class="bold">
                      {{ calculateAdvantageStrength(competitor.advantageValue5) }}
                    </p>
                  </li>
                  <!-- /Advantage 5 -->

                </ul>

                <div class="dashed-divider" />
              </div>

              <div
                class="threat"
                :class="[getCompetitorLevel(competitor) === $t('medium') ? 'medium': '' || getCompetitorLevel(competitor) === $t('low') ? 'low': '']"
              >
                <p>{{ $t('pages.businessPlan.sections.competitors.threatLevel') }}</p>
                <p class="title">
                  {{ getCompetitorLevel(competitor) }}
                </p>
              </div>
            </div>
          </div>
          <!--</div>-->
        </div>
      </template>

      <!-- More Details section -->
      <template v-if="moreDetails && isOption(competitorData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="competitorData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- More Details section -->
    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import TogglePageItemsButton from '@/views/Home/StoryMode/Components/BusinessPlan/TogglePageItemsButton'
import ToggleViewButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleViewButton'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'Competitors',

  components: {
    EditSectionLink,
    EditableDescription,
    EditableTitle,
    ToggleNotesButton,
    TogglePageItemsButton,
    ToggleViewButton
  },

  mixins: [
    journalMethods,
    MixinIdeaRoles,
    MixinUserSubscription
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardExist: true,
      competitorData: {},
      moreVisible: false,
      competitors: this.$store.state.idea.storyMode.research.competitors.competitors,
      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.competitorsMoreDetails
    }
  },

  computed: {
    isCompetitorsVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'Competitors')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    isTitleEnabled () {
      return this.competitorData.title
    },

    isMoreDetailsTitleEnabled () {
      return this.competitorData.moreDetailsTitle
    }
  },

  watch: {
    allPagesTextData () {
      this.competitorData = Object.assign({}, this.allPagesTextData)
    }
  },

  mounted () {
    this.competitorData = Object.assign({}, this.allPagesTextData)
  },

  methods: {
    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.competitorData, 'gridListView')
        this.competitorData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.competitorData, 'moreDetailsDisplay')
        this.competitorData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    onSave () {
      this.$emit('save', this.competitorData)
    },

    enableCompetitors () {
      this.toggleOption(this.competitorData, 'competitorTable')
      this.competitorData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    },

    calculateAdvantageStrength (value) {
      if (value <= 30) {
        return this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.poor')
      } else if (value <= 60) {
        return this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.fair')
      } else if (value <= 90) {
        return this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.good')
      }

      return this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.excellent')
    },

    getCompetitorLevel (competitor) {
      // noinspection JSUnresolvedVariable
      const advantageLevel = competitor.advantageValue1 + competitor.advantageValue2 + competitor.advantageValue3 +
        competitor.advantageValue4 + competitor.advantageValue5

      if (advantageLevel <= 200) {
        return this.$t('low')
      } else if (advantageLevel <= 400) {
        return this.$t('medium')
      }

      return this.$t('high')
    }
  }
}
</script>
