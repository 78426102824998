<template>
  <div
    v-if="!subscriptionIsFreeTrial && $store.state.idea.storyMode.common.completedSteps.swotAnalysisCompleted && isSwotAnalysisVisible"
    class="journal-content dynamic-height"
  >
    <div class="content-menus">
      <div v-if="moreVisible === true" class="view-type">
        <a
          v-if="strengths"
          class="orange"
          :class="{'orange-off': !isOption(swotAnalysisData, 'strengthsCard')}"
          @click="enableStrengths"
        >
          {{ $t('pages.businessPlan.sections.swotAnalysis.strengths') }}
        </a>
        <a
          v-if="weaknesses"
          class="orange"
          :class="{'orange-off': !isOption(swotAnalysisData, 'weaknessesCard')}"
          @click="enableWeaknesses"
        >
          {{ $t('pages.businessPlan.sections.swotAnalysis.weaknesses') }}
        </a>
        <a
          v-if="opportunities"
          class="orange"
          :class="{'orange-off': !isOption(swotAnalysisData, 'opportunitiesCard')}"
          @click="enableOpportunities"
        >
          {{ $t('pages.businessPlan.sections.swotAnalysis.opportunities') }}
        </a>
        <a
          v-if="threats"
          class="orange"
          :class="{'orange-off': !isOption(swotAnalysisData, 'threatsCard')}"
          @click="enableThreats"
        >
          {{ $t('pages.businessPlan.sections.swotAnalysis.threats') }}
        </a>

        <p v-if="!strengths && !weaknesses && !opportunities && !threats">
          {{ $t('pages.businessPlan.sections.youDontHaveAnyDataOnThisPage') }}
        </p>
      </div>
      <div v-if="canEdit" class="mini-actions">
        <toggle-page-items-button
          :visible="moreVisible"
          @click="toggleMore"
        />
        <toggle-view-button
          :disabled="!cardExist"
          :horizontal="!isOption(swotAnalysisData, 'gridListView')"
          @click="toggleRotate"
        />
        <toggle-notes-button
          :disabled="!moreDetails"
          :visible="isOption(swotAnalysisData, 'moreDetailsDisplay')"
          @click="enableMoreDetails"
        />
        <edit-section-link route-name="business-guide-research-swot-analysis" />
      </div>
    </div>

    <div class="sub chapter">
      <header>
        <!-- Title section -->
        <editable-title
          v-model="swotAnalysisData.title"
          tag="h1"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Title section -->
        <hr>
        <!-- Description section -->
        <editable-description
          v-model="swotAnalysisData.subTitle"
          :empty-text="$t('addMoreDetails.addNoteText')"
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
        <!-- Description section -->
      </header>

      <template v-if="strengths">
        <div v-if="isOption(swotAnalysisData, 'strengthsCard')" class="journal-swot-analysis-card strengths">
          <h1>{{ $t('pages.businessPlan.sections.swotAnalysis.strengths') }}</h1>
          <hr>
          <span class="editor-wrapper" v-html="strengths" />
          <span class="text-mark">
            S
          </span>
        </div>
      </template>

      <template v-if="weaknesses">
        <div v-if="isOption(swotAnalysisData, 'weaknessesCard')" class="journal-swot-analysis-card weaknesses">
          <h1>{{ $t('pages.businessPlan.sections.swotAnalysis.weaknesses') }}</h1>
          <hr>
          <span class="editor-wrapper" v-html="weaknesses" />
          <span class="text-mark">
            W
          </span>
        </div>
      </template>

      <template v-if="opportunities">
        <div v-if="isOption(swotAnalysisData, 'opportunitiesCard')" class="journal-swot-analysis-card opportunities">
          <h1>{{ $t('pages.businessPlan.sections.swotAnalysis.opportunities') }}</h1>
          <hr>
          <span class="editor-wrapper" v-html="opportunities" />
          <span class="text-mark">
            O
          </span>
        </div>
      </template>

      <template v-if="threats">
        <div v-if="isOption(swotAnalysisData, 'threatsCard')" class="journal-swot-analysis-card threats">
          <h1>{{ $t('pages.businessPlan.sections.swotAnalysis.threats') }}</h1>
          <hr>
          <span class="editor-wrapper" v-html="threats" />
          <span class="text-mark">
            T
          </span>
        </div>
      </template>

      <!-- More Details section -->
      <template v-if="moreDetails && isOption(swotAnalysisData, 'moreDetailsDisplay')">
        <div class="details">
          <editable-title
            v-model="swotAnalysisData.moreDetailsTitle"
            class="bold"
            tag="p"
            :disabled="!canEdit"
            @save="onSave"
          />
          <p v-html="moreDetails" />
        </div>
      </template>
      <!-- More Details section -->
    </div>
  </div>
</template>

<script>
import EditSectionLink from '@/views/Home/StoryMode/Components/BusinessPlan/EditSectionLink'
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import ToggleNotesButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleNotesButton'
import TogglePageItemsButton from '@/views/Home/StoryMode/Components/BusinessPlan/TogglePageItemsButton'
import ToggleViewButton from '@/views/Home/StoryMode/Components/BusinessPlan/ToggleViewButton'
import journalMethods from '@/mixins/journalMethods'

export default {
  name: 'SwotAnalysis',

  components: {
    EditSectionLink,
    EditableDescription,
    EditableTitle,
    ToggleNotesButton,
    TogglePageItemsButton,
    ToggleViewButton
  },

  mixins: [
    journalMethods,
    MixinIdeaRoles,
    MixinUserSubscription
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cardExist: false,
      moreVisible: false,
      swotAnalysisData: {},
      swotAnalysis: [],
      strengths: null,
      weaknesses: null,
      opportunities: null,
      threats: null,
      moreDetails: this.$store.state.idea.storyMode.common.moreDetails.swotAnalysisMoreDetails
    }
  },

  computed: {
    isSwotAnalysisVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'SwotAnalysis')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    isTitleEnabled () {
      return this.swotAnalysisData.title
    },

    isMoreDetailsTitleEnabled () {
      return this.swotAnalysisData.moreDetailsTitle
    }
  },

  watch: {
    allPagesTextData () {
      this.swotAnalysisData = Object.assign({}, this.allPagesTextData)
    }
  },

  mounted () {
    this.swotAnalysisData = Object.assign({}, this.allPagesTextData)
    this.getStrengths()
    this.getWeaknesses()
    this.getOpportunities()
    this.getThreats()
  },

  methods: {
    getStrengths () {
      if (this.$store.state.idea.storyMode.research.swotAnalysis.strengths) {
        this.strengths = this.$store.state.idea.storyMode.research.swotAnalysis.strengths

        return
      }

      this.$http.get('story-mode/research/swot/strengths', { params: { ideaId: this.$store.state.idea.id } })
        .then(response => {
          this.strengths = response.data.payload.swotAnalysisStrengths
          this.$store.commit('idea/setSwotAnalysisStrengths', response.data.payload.swotAnalysisStrengths)
        })
    },

    getWeaknesses () {
      if (this.$store.state.idea.storyMode.research.swotAnalysis.weaknesses) {
        this.weaknesses = this.$store.state.idea.storyMode.research.swotAnalysis.weaknesses

        return
      }

      this.$http.get('story-mode/research/swot/weaknesses', { params: { ideaId: this.$store.state.idea.id } })
        .then(response => {
          this.weaknesses = response.data.payload.swotAnalysisWeaknesses
          this.$store.commit('idea/setSwotAnalysisWeaknesses', response.data.payload.swotAnalysisWeaknesses)
        })
    },

    getOpportunities () {
      if (this.$store.state.idea.storyMode.research.swotAnalysis.opportunities) {
        this.opportunities = this.$store.state.idea.storyMode.research.swotAnalysis.opportunities

        return
      }

      this.$http.get('story-mode/research/swot/opportunities', { params: { ideaId: this.$store.state.idea.id } })
        .then(response => {
          this.opportunities = response.data.payload.swotAnalysisOpportunities
          this.$store.commit('idea/setSwotAnalysisOpportunities', response.data.payload.swotAnalysisOpportunities)
        })
    },

    getThreats () {
      if (this.$store.state.idea.storyMode.research.swotAnalysis.threats) {
        this.threats = this.$store.state.idea.storyMode.research.swotAnalysis.threats

        return
      }

      this.$http.get('story-mode/research/swot/threats', { params: { ideaId: this.$store.state.idea.id } })
        .then(response => {
          this.threats = response.data.payload.swotAnalysisThreats
          this.$store.commit('idea/setSwotAnalysisThreats', response.data.payload.swotAnalysisThreats)
        })
    },

    toggleRotate () {
      if (this.cardExist) {
        this.toggleOption(this.swotAnalysisData, 'gridListView')
        this.swotAnalysisData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    enableMoreDetails () {
      if (this.moreDetails) {
        this.toggleOption(this.swotAnalysisData, 'moreDetailsDisplay')
        this.swotAnalysisData = Object.assign({}, this.allPagesTextData)
        this.onSave()
      }
    },

    onSave () {
      this.$emit('save', this.swotAnalysisData)
    },

    enableStrengths () {
      this.toggleOption(this.swotAnalysisData, 'strengthsCard')
      this.swotAnalysisData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    },

    enableWeaknesses () {
      this.toggleOption(this.swotAnalysisData, 'weaknessesCard')
      this.swotAnalysisData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    },

    enableOpportunities () {
      this.toggleOption(this.swotAnalysisData, 'opportunitiesCard')
      this.swotAnalysisData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    },

    enableThreats () {
      this.toggleOption(this.swotAnalysisData, 'threatsCard')
      this.swotAnalysisData = Object.assign({}, this.allPagesTextData)
      this.onSave()
    }
  }
}
</script>
